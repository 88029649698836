<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <div class="text-overline secondary--text mb-3">Account</div>
      <h2 class="text-h3 text-lg-h2 mb-6">Ваш аккаунт</h2>
    </v-responsive>

    <v-card class="pa-4 mx-auto" max-width="600">
      <v-card-text>
        <v-form ref="form" v-model="formValidity" @submit.prevent="submit">
          <v-text-field
            v-model="form.username"
            autocomplete="username"
            label="Email"
            outlined
            :rules="rules.emailRules"
            required
          ></v-text-field>
          <v-text-field
            v-model="form.password"
            autocomplete="current-password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            label="Пароль"
            outlined
            hide-details
            :rules="rules.passwordRules"
            required
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-checkbox v-model="form.rememberMe" dense label="Доверять этому браузеру"></v-checkbox>
          <v-btn
            type="submit"
            block
            class="secondary"
            x-large
            :disabled="!formValidity"
          >Войти
          </v-btn>
        </v-form>
        <div class="text-center mt-2 text-body-2">
          <router-link to="#">Забыли пароль?</router-link>
        </div>
        <div class="d-flex my-3">
          <v-divider class="my-1"></v-divider>
          <div class="text-overline mx-1">Или войти с помощью</div>
          <v-divider class="my-1"></v-divider>
        </div>

        <div class="d-flex justify-space-between">
          <v-btn outlined large class="flex-grow-1" color="secondary lighten-1">
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn outlined large class="flex-grow-1 mx-2" color="secondary lighten-1">
            <v-icon>mdi-twitter</v-icon>
          </v-btn>
          <v-btn outlined large class="flex-grow-1" color="secondary lighten-1">
            <v-icon>mdi-google</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <div class="text-overline text-uppercase mt-3 text-center">
      Входя в систему, вы соглашаетесь с<br/>
      <router-link :to="{ name: 'legal.terms-of-service'}">Условиями использования</router-link>
      &
      <router-link :to="{ name:'legal.privacy-policy' }">Политикой конфиденциальности</router-link>
      сервиса
    </div>
  </v-container>
</template>

<script>
import ToastService from '@/service/ToastService'
import LoginForm from '@/model/auth/LoginForm'
import { ruleEmail, ruleRequired } from '@/utils/FormRules'

export default {
  name: 'SigninPage',
  data: () => ({
    showPassword: false,
    formValidity: false,
    form: {
      username: '',
      password: '',
      rememberMe: true
    },
    rules: {
      emailRules: [ruleRequired(), ruleEmail()],
      passwordRules: [ruleRequired()]
    }
  }),
  created() {
    this.$store.dispatch('auth/logout')
  },
  methods: {
    submit() {
      const loginForm = LoginForm.create(this.form)

      this.$store.dispatch('auth/login', { loginForm })
        .then(() => {
          this.$router.push({ name: 'home' })
        })
        .catch(() => {
          ToastService.info('Ошибка авторизации.')
        })
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Sign In'
      }
    }
  }
}
</script>

<style scoped>

</style>
